import getCustomFieldValue from '@/stories/utils/getCustomFieldValue';
import isValidCustomFieldValue from '@/stories/utils/isValidCustomFieldValue';

function getVisibilityFieldValue(field, customFieldValues) {
  const customValue = customFieldValues.find(
    (cv) => field.visibility && cv.key === field.visibility.field_id,
  );
  return customValue ? getCustomFieldValue(customValue.value) : null;
}

function isMatchVisibilityValues(field, visibilityFieldValue) {
  const visibilityValue = field.visibility.field_value;

  if (Array.isArray(visibilityFieldValue)) {
    return visibilityFieldValue.includes(String(visibilityValue));
  }
  return String(visibilityFieldValue) === String(visibilityValue);
}

/**
 * @param {object} field
 * @param {string} field.visibility.operator
 * @param {Array} customFieldValues
 * @returns {boolean}
 */
export default function isCustomFieldVisible(field, customFieldValues) {
  const visibilityFieldValue = getVisibilityFieldValue(field, customFieldValues);

  if (!field.visibility) {
    return true;
  }

  switch (field.visibility.operator) {
    case '1': // is set or has a value
      return isValidCustomFieldValue(visibilityFieldValue);
    case '2': // is not set or does not have a value
      return !isValidCustomFieldValue(visibilityFieldValue);
    case '3': // equals or includes
      return isMatchVisibilityValues(field, visibilityFieldValue);
    case '4': // does not equal or does not include
      return !isMatchVisibilityValues(field, visibilityFieldValue);
    default:
      return true;
  }
}
